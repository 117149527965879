$(document).ready(function () {
    if ($('#kirim-karya').length > 0) {
        var eImg = $('.image-upload'),
            imgInput = eImg.find('.upload-form')

        var switchTab = function (index) {
            $('.karya-navigation li').removeClass('active')
            $('.karya-navigation li').eq(index).addClass('active')
            $('.form-steps').removeClass('show')
            $('.form-steps').eq(index).addClass('show')
        }

        var error = function (msg) {
            if (msg === false) {
                $('.error-msg').addClass('hide')
            } else {
                $('.error-msg').html(msg)
                $('.error-msg').removeClass('hide')
            }
        }

        $('#kirim-karya').on('submit', function (e) {
            var t = $(this),
                step = t.data('step'),
                err = ''

            if (t.valid() === false) {
                return false
            }

            var data = $('#kirim-karya').serializeArray()
            error(false)
            var doProcess = true
            e.preventDefault()

            /**
             * Isi Biodata Step
             */
            if (step == 1) {
                switchTab(1)
                t.data('step', 2)
            }
            /**
             * Isi Cerita
             */
            if (step == 2) {
                data.forEach(function (d) {
                    if (d.name == 'genre' && d.value == 'null') {
                        err += '<p>Silahkan pilih genre</p>'
                    }
                })

                if (err.length === 0) {
                    switchTab(2)
                    t.data('step', 3)
                }
            }
            /**
             * Isi Episode
             */
            if (step == 3) {
                var ep = 0
                data.forEach(function (d) {
                    if (d.name == 'episode-content[]') {
                        ep++
                    }
                })
                if (ep < 8) {
                    err += '<p>Ceritera harus terdiri dari 8 Episode</p>'
                    switchTab(2)
                } else {
                    var data = $('#kirim-karya').serialize()
                    $.post(baseURL + 'stories/store', data).done(function (result) {
                        var response = $.parseJSON(result)
                        if (response.code == 200) {
                            noty({ text: response.message, layout: 'topCenter', type: 'success', timeout: 3000 })
                            window.setTimeout(function () {
                                window.location.href = baseURL
                            }, 5000)
                        } else {
                            noty({ text: response.message, layout: 'topCenter', type: 'error', timeout: 3000 })
                        }
                    })
                }
            }
            if (err.length > 0) {
                error(err)
            }

        }).validate({})

        $('.karya-navigation li').on('click', function () {
            var index = $('.karya-navigation li').index($(this))
            switch (index) {
                case 0:
                    switchTab(0)
                    break
                case 1:
                    if ($('#kirim-karya').data('step') >= 2) {
                        switchTab(1)
                    }
                    break
                case 2:
                    if ($('#kirim-karya').data('step') >= 3) {
                        switchTab(2)
                    }
                    break
            }
        })

        // $("input[name=episode]").on('change', function(e) {
        //   var ep = $(this).val(),
        //       form = $("#kirim-karya"),
        //       epId = "story-ep-" + ep;
        //   if(form.find('#'+epId).length == 0) {
        //     form.append("<input type='hidden' name='episode-content[]' id='"+epId+"'>");
        //     form.find('#'+epId).val("[]");
        //   }
        //   var story = JSON.parse(form.find('#'+epId).val());
        //   $("#dynamic-form-title").val(story.title);
        //   $("#dynamic-form-content").val(story.content);
        // });

        var changeBinding = function (e) {
            var t = $(this).parents('.episode-lists')
            var epId = 'story-ep-' + t.data('ep')
            // var ep = $("input[name=episode]:checked").val(),
            //     form = $("#kirim-karya"),
            //     epId = "story-ep-" + ep;

            var story = {
                ep: t.data('ep'),
                title: t.find('.dynamic-form-title').val(),
                content: t.find('.dynamic-form-content').val()
            }

            if (t.find('#' + epId).length == 0) {
                t.append('<input type=\'hidden\' name=\'episode-content[]\' id=\'' + epId + '\'>')
            }

            if (story.title.length <= 0 || story.content.length <= 0) {
                t.parent().find('.ep-title span').text('')
                t.find('input[type=hidden]').remove()
            } else {
                t.parent().find('.ep-title span').text(' - ' + story.title)
                t.find('input[type=hidden]').val(JSON.stringify(story))
            }

        }

        /**
         * Image Upload
         */

        imgInput.on('change', function () {
            var file = $(this)[0].files[0]
            if (file.size <= 204800 && /jpeg|jpg/.test(file.type)) {
                var r = new FileReader()
                r.onload = function (e) {
                    eImg.addClass('has-image')
                    eImg
                        .find('img.image-placeholder')
                        .attr('src', e.target.result)
                        .show()
                }
                r.readAsDataURL(file)
            }
        })

        $('.episode-lists-outer').on('click', '.ep-title', function (e) {
            e.preventDefault()
            $(this).parent().find('.episode-lists').stop().slideToggle()
        })
    }
})
