$(document).ready(function () {
    Noty.overrideDefaults({
        layout: 'topCenter',
        theme: 'light',
        timeout: 4000,
        progressBar: true,
        closeWith: ['click'],
    });

    const notifySuccess = (message) => {
        new Noty({
            text: message,
            type: 'success',
        }).show()
    };

    const notifyError = (message) => {
        new Noty({
            text: message,
            type: 'error',
        }).show();
    };

    var resizeDimension = function (e) {
        /**
         * Story Responsive
         */
        $('.list-story').each(function () {
            var width = $(this).find('li').width() - 20
            var storyItem = $(this).find('footer.story-info')
            storyItem.find('h1.title')
                .css('width', width)
                .addClass('ellips')
            storyItem.find('p.sub-title')
                .css('width', width)
                .addClass('ellips')
        })
    }

    $(window).on('resize', resizeDimension)

    setTimeout(function () {
        $('.week-list').each(function () {
            if ($(this).find('> li').length <= 3) {
                $(this).parent().addClass('no-slide')
            } else {
                $(this).cycle({
                    fx: 'carousel',
                    timeout: 0,
                    carouselVisible: 3,
                    carouselVertical: true,
                    allowWrap: false,
                    slides: '> li',
                    next: $(this).parent().find('.down-nav'),
                    prev: $(this).parent().find('.up-nav')
                })
            }
        })
        resizeDimension()

        $('.list-horz-ep').cycle({
            fx: 'carousel',
            carouselVisible: 5,
            timeout: 0,
            allowWrap: false,
            carouselFluid: true,
            slides: '> li',
            next: '#nav-ep-right',
            prev: '#nav-ep-left'
        })

    }, 100)

    $('body').on('mouseenter', '.star-voted > i', function (e) {
        var p = $(this).parent(),
            index = $(this).parent().find('i').index($(this)) + 1
        p.find('i')
            .removeClass('fa-star')
            .removeClass('fa-star-o')
            .addClass('fa-star-o')
        p.find('i').each(function (i, e) {
            if (i < index)
                $(this).removeClass('fa-star-o')
                    .addClass('fa-star')
        })
    })
    $('body').on('mouseleave', '.star-voted', function (e) {
        var p = $(this)
            , c = p.data('vote')

        p.find('i')
            .removeClass('fa-star')
            .removeClass('fa-star-o')
            .addClass('fa-star-o')
        p.find('i').each(function (i, e) {
            if (i < c)
                $(this).removeClass('fa-star-o')
                    .addClass('fa-star')
        })
    })

    $('body').on('click', '.star-voted > i', function (e) {
        var p = $(this).parent(),
            index = $(this).parent().find('i').index($(this)) + 1
        p.data('vote', index)
    })

    /**
     * Home Dropdown Menu Change
     */
    var $dropDownCat = $('.dropdown.select-category')
    $dropDownCat.on('click', '.dropdown-menu a', function () {
        var tag = $(this),
            dp = tag.parents('.dropdown').find('> a'),
            target = $(dp.data('parse-target'))


        var genre_id = tag[0].className
        if (target.selector == '#data-terpopular') {
            var icon = 'fa-eye'
            var url = 'stories/popular.json?genre_id=' + genre_id
            var value = '<span>{{view}}</span>'
        } else {
            var icon = 'fa-star'
            var url = 'stories/best_rating.json?genre_id=' + genre_id
            var value = '<span>{{rating}}</span>'
        }
        tag.parents('.select-category').find('.tag').html(tag.html())

        target.addClass('loading')

        /**
         * Generated Layout
         */
        var layout =
            '<li>' +
            '<a href="stories/{{id}}-{{slug}}">' +
            '<figure>' +
            '<img src="img/stories/covers/{{img}}" alt="">' +
            '</figure>' +
            '<div class="detail">' +
            '<span class="tag">{{genreName}}</span>' +
            '<h1 class="title">{{title}}</h1>' +
            '<p class="sub-title">by {{author}}</p>' +
            '<p class="rating">' +
            '<i class="fa ' + icon + ' m-r-sm"></i>' +
            value +
            '</p>' +
            '</div>' +
            '</a>' +
            '</li>'

        $.getJSON(url)
            .done(function (data) {
                var showData = function () {
                    target.removeClass('loading')
                    target.html('')
                    data.popular_stories.forEach(function (d, i) {
                        var valueThousand = d.view.toLocaleString('id-ID', {
                            minimumFractionDigits: 0
                        })
                        target.append(
                            layout
                                .replace('{{id}}', d.id)
                                .replace('{{slug}}', d.slug)
                                .replace('{{img}}', d.stories_covers[0].image)
                                .replace('{{author}}', d.users[0].fullname)
                                .replace('{{rating}}', d.rating)
                                .replace('{{view}}', valueThousand)
                                .replace('{{genreName}}', d.genre.name)
                                .replace('{{title}}', d.title)
                        )
                    })
                }

                /**
                 * For network simulation
                 */
                setTimeout(showData, 1000)

                console.log(data)
            })
            .fail(function () {
                target.removeClass('loading')
                console.log('error while parse data')
            })
    })

    $('.show-sinopsis').click(function (e) {
        e.preventDefault()
        $('.sinopsis').addClass('hide')
        $('.sinopsis-hide').removeClass('hide')
        $('.show-sinopsis').addClass('hide')
    })

    const getReferenceId = () => {
        return $('.reference_id').text();
    }

    const getReferenceType = () => {
        return $('.reference_type').text();
    }


    $('#input-id').rating().on('rating:change', function (event, value, caption) {
        const answer = confirm('Apakah Anda akan memberikan rating ' + value + ' ?')
        const referenceId = getReferenceId()
        const referenceType = getReferenceType()

        if (answer == true) {
            $.ajax({
                method: 'POST',
                url: webroot + 'api/users_stars',
                data: {value, reference_id: referenceId, type: referenceType},
                headers: {
                    'Accept': 'application/json'
                },
                success: function (data, textStatus, request) {
                    console.log(data)
                    const response = $.parseJSON(data)
                    if (response.code === 200) {
                        notifySuccess(response.message)
                        $('#input-id').rating('refresh', {disabled: true, showClear: false})
                    } else {
                        $('#input-id').rating('refresh', {disabled: true, showClear: false})
                    }
                },
                error: function (request, textStatus, error) {
                    notifyError("Terjadi kesalahan, rating yang Anda berikan tidak dapat disimpan")
                }
            })
        }
    })


    $('#input-cerpen-id').rating().on('rating.change', function (event, value, caption) {
        var r = confirm('Apakah Anda akan memberikan rating ' + value + ' ?')
        if (r == true) {
            $.post(webroot + 'cerpen/add_star', {'value': value}).done(function (result) {
                var response = $.parseJSON(result)
                if (response.code == 200) {
                    noty({text: response.message, layout: 'topCenter', type: 'success', timeout: 3000})
                    $('#input-id').rating('refresh', {disabled: true, showClear: false})
                } else {
                    noty({text: response.message, layout: 'topCenter', type: 'error', timeout: 3000})
                    $('#input-id').rating('refresh', {disabled: true, showClear: false})
                }
            })
        }
    })

    const defaultCoverSmall = baseURL + 'img/default-cover-small.jpg';

    const layoutEpisodeNewest =
        '<div class="story-list__item">' +
        '<figure>' +
        '<img src="' + baseURL + 'img/public/stories/covers/{{cover}}" alt="{{storyTitle}}" onerror="this.src=\'' + defaultCoverSmall + '\'">' +
        '</figure>' +
        '<div class="story-list__item-detail">' +
        '<h2 class="story-list__item-title">' +
        '<a href="' + baseURL + 'stories/{{storyId}}-{{storySlug}}" title="{{storyTitle}}">' +
        '{{storyTitle}}' +
        '</a>' +
        '</h2>' +
        '<div class="story-list__item-sort">Ep. {{sort}}</div>' +
        '<div class="story-list__item-episode">' +
        '<a href="' + baseURL + 'episodes/{{episodeId}}-{{episodeSlug}}" title="{{episodeTitle}}">' +
        '{{episodeTitle}}' +
        '</a>' +
        '</div>' +
        '<div class="story-list__item-user"><i class="fa fa-user-circle-o"></i> {{user}}</div>' +
        '<div class="story-list__item-date"><i class="fa fa-calendar-o"></i> {{publishAtShort}}</div>' +
        '</div>' +
        '</div>' +
        '</div>'

    const layoutCerpenNewest =
        '<div class="story-list__item">' +
        '<figure>' +
        '<img src="' + baseURL + 'img/public/cerpen/covers/{{image}}" alt="{{title}}" onerror="this.src=\'' + defaultCoverSmall + '\'">' +
        '</figure>' +
        '<div class="story-list__item-detail">' +
        '<h2 class="story-list__item-title">' +
        '<a href="' + baseURL + 'cerpen/{{id}}-{{slug}}" alt="{{title}}">' +
        '{{title}}' +
        '</a>' +
        '</h2>' +
        '<div class="story-list__item-user"><i class="fa fa-user-circle-o"></i> {{user}}</div>' +
        '<div class="story-list__item-date"><i class="fa fa-calendar-o"></i> {{publishAtShort}}</div>' +
        '</div>' +
        '</div>' +
        '</div>'

    const storyList = parent => {
        return $(parent + ' .story-list')
    }

    $('.latest-ceritera__episode').ready(function () {
        const container = storyList('.latest-ceritera__episode-body')
        if (container.length > 0) {
            container.html('')
            loadEpisodes(container)
        }
    })

    $('.latest-ceritera__cerpen').ready(function () {
        const container = storyList('.latest-ceritera__cerpen-body')
        if (container.length > 0) {
            loadCerpen(container)
        }
    })

    $('ul.nav-genre__episode li').on('click', function (e) {
        const container = storyList('.latest-ceritera__episode-body')
        const genre = this.className

        if (container.length > 0) {
            container.html('')
            loadEpisodes(container, {genre})
            $('.latest-ceritera__episode-filter-genre-id').text(genre);
            $('.latest-ceritera__episode-filter-page').text(1);
        }
    })

    $('ul.nav-genre__cerpen li').on('click', function (e) {
        const container = storyList('.latest-ceritera__cerpen-body')
        const genre = this.className;

        if (container.length > 0) {
            loadCerpen(container, {genre})
            $('.latest-ceritera__cerpen-filter-genre-id').text(genre);
        }
    })

    $('.btn-episode-load-more').on('click', function (e) {
        e.preventDefault()
        const container = storyList('.latest-ceritera__episode-body')
        const genre = $('.latest-ceritera__episode-filter-genre-id').text()
        const latestPage = $('.latest-ceritera__episode-filter-page').text()
        const nextPage = parseInt(latestPage) + 1

        if (container.length > 0) {
            loadEpisodes(container, {genre, page: nextPage})
            $('.latest-ceritera__episode-filter-page').text(nextPage)
        }
    })

    $('.btn-cerpen-load-more').on('click', function (e) {
        e.preventDefault()
        const container = storyList('.latest-ceritera__cerpen-body')
        const genre = $('.latest-ceritera__cerpen-filter-genre-id').text()
        const latestPage = $('.latest-ceritera__cerpen-filter-page').text()
        const nextPage = parseInt(latestPage) + 1

        if (container.length > 0) {
            loadCerpen(container, {genre, page: nextPage})
            $('.latest-ceritera__cerpen-filter-page').text(nextPage)
        }
    })

    const loadEpisodes = function (container, options) {
        showLoading(container);
        $.getJSON(baseURL + 'api/episodes', options)
            .done(function (response) {
                response.data.forEach(function (episode) {
                    let cover = (episode.story.stories_covers.length > 0) ? episode.story.stories_covers[0].image : 'default-cover-small.jpg';
                    container.append(
                        layoutEpisodeNewest
                            .replace('{{episodeId}}', episode.id)
                            .replace('{{episodeSlug}}', episode.slug)
                            .replace('{{episodeTitle}}', episode.title)
                            .replace('{{episodeTitle}}', episode.title)
                            .replace('{{sort}}', episode.sort)
                            .replace('{{publishAtShort}}', episode.publish_at_short)
                            .replace('{{cover}}', cover)
                            .replace('{{storyId}}', episode.story.id)
                            .replace('{{storySlug}}', episode.story.slug)
                            .replace('{{storyTitle}}', episode.story.title)
                            .replace('{{storyTitle}}', episode.story.title)
                            .replace('{{storyTitle}}', episode.story.title)
                            .replace('{{user}}', episode.story.user.fullname)
                    )
                })
                const remainingData = (options && options.page) ? response.total - (options.page * 5) : response.total - 5

                if (remainingData < 1) {
                    $('.btn-episode-load-more').addClass('hidden')
                } else {
                    $('.btn-episode-load-more').removeClass('hidden')
                }
                hideLoading(container);
            })
            .fail(function () {
                hideLoading(container);
                console.log('Failed load episode')
            })
    }

    const loadCerpen = function (container, options) {
        showLoading(container);
        $.getJSON(baseURL + 'api/cerpen', options)
            .done(function (response) {
                response.data.forEach(function (cerpen, i) {
                    let cover = (cerpen.image || 'default-cover-small.jpg');
                    container.append(
                        layoutCerpenNewest
                            .replace('{{id}}', cerpen.id)
                            .replace('{{slug}}', cerpen.slug)
                            .replace('{{title}}', cerpen.title)
                            .replace('{{title}}', cerpen.title)
                            .replace('{{title}}', cerpen.title)
                            .replace('{{publishAtShort}}', cerpen.publish_at_short)
                            .replace('{{image}}', cover)
                            .replace('{{user}}', cerpen.user.fullname)
                    )
                })
                const remainingData = (options && options.page) ? response.total - (options.page * 5) : response.total - 5

                if (remainingData < 1) {
                    $('.btn-cerpen-load-more').addClass('hidden')
                } else {
                    $('.btn-cerpen-load-more').removeClass('hidden')
                }
                hideLoading(container);
            })
            .fail(function () {
                hideLoading(container);
                console.log('Failed load episode')
            })
    }

    const showLoading = (element) => {
        $(element).LoadingOverlay("show", {
            background: "rgba(165, 190, 100, 0.5)"
        });
    }

    const hideLoading = (element) => {
        $(element).LoadingOverlay("hide", true);
    }
})
